/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/

'use client';

import { useEffect } from 'react';
import { redirect } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { LOGGEDIN_OKAHU_USER } from '@/services/request';

import useApps from '@/hooks/useApps';

import BasicLoader from '../spinner/basic-loader';
import { AccountType } from './account-type';

export const HoldingPage = () => {
  const { loading, error, data } = useApps();
  const { locale, messages } = useLanguage();
  const langData = messages?.Account;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(`${LOGGEDIN_OKAHU_USER}`, Date.now().toString());
    }
  }, []);

  if (!loading) {
    if (error) {
      if (error.data.error.code === 'MISSING_TENANT_CLAIM') {
        return <AccountType />;
      }
    }
    if (data) {
      redirect(`/${locale}/apps`);
    }
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-y-6">
      <BasicLoader className="size-10" />
      <div className="space-y-2 text-center">
        <p className="text-body text-primary">{langData?.sign_in_successful}</p>
        <p className="text-body-sm text-form-input-disabled">
          {messages?.General?.please_wait}..
        </p>
      </div>
    </div>
  );
};
