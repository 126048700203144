/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { useUser } from '@auth0/nextjs-auth0/client';
import { CheckIcon, Smile } from 'lucide-react';

import { cn } from '@/lib/utils';

import { CustomButton } from '../common';
import { Checkbox } from '../ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { Provisioning } from './account-provisioning';

export const AccountType = () => {
  const [hover, setHover] = useState(false);
  const [showProvisioning, setShowProvisioning] = useState(false);
  const [interested, setInterested] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { messages } = useLanguage();
  const langData = messages?.Account;
  const termsData = messages?.TermsAndConditions;
  const { user } = useUser();

  const handleLogout = () => {
    if (typeof window !== 'undefined' && user) {
      window.location.href = '/api/auth/logout';
    }
  };

  useEffect(() => {
    localStorage.setItem('NEW_OKAHU_USER', 'true');
  }, []);

  return (
    <>
      {!showProvisioning ? (
        <div className="flex h-screen items-center justify-center">
          <div className="flex flex-col items-center gap-y-8 text-white">
            <Image
              src="/logos/full-color-reversed/medium.svg"
              width={100}
              height={100}
              alt="Logo"
            />
            <div className="space-y-1 text-center">
              <h1 className="text-h1 font-normal">{langData?.welcome}!</h1>
              <p className="text-body text-body-subtle">
                {langData?.please_choose_the_type_of_account}:
              </p>
            </div>
            <div className="flex gap-x-4">
              <div className="relative rounded-lg border border-jade bg-canvas-background-primary px-[30px] py-[38.5px] shadow-[0px_0px_12px_0px_#00000080]">
                {/* TODO: shadow tailwind config */}
                <div className="flex w-[180px] flex-col items-center text-center">
                  <Image
                    src="/logos/user.svg"
                    className="size-16"
                    width={100}
                    height={100}
                    alt="Logo"
                  />
                  <div className="mt-3 space-y-2">
                    <h3 className="text-h3 font-normal">
                      {langData?.personal}
                    </h3>
                    <p className="text-body-sm">
                      {langData?.for_individual_use}
                    </p>
                  </div>
                </div>
                <div className="absolute left-[200px] top-[16.16px] flex size-6 items-center justify-center rounded-full bg-jade text-white">
                  <CheckIcon size={16} />
                </div>
              </div>

              <div
                className="relative overflow-hidden rounded-lg border border-line-secondary bg-canvas-background-primary px-[38.5px] py-[30px]"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <div className="flex w-[180px] flex-col items-center text-center">
                  <Image
                    src="/logos/org.svg"
                    className="size-16"
                    width={100}
                    height={100}
                    alt="Logo"
                  />
                  <div className="mt-3 space-y-2">
                    <h3 className="text-h3 font-normal">
                      {langData?.organization}
                    </h3>
                    <p className="text-body-sm">
                      {langData?.for_business_teams_and_groups}
                    </p>
                  </div>
                </div>

                {/* Hover functionality for interest capture */}
                <div
                  className={cn(
                    'absolute inset-0 hidden rounded-lg bg-black/[.64] backdrop-blur-sm',
                    hover && 'block',
                    interested && 'hidden'
                  )}
                >
                  {hover && (
                    <div className="flex h-full flex-col items-center justify-center gap-y-4">
                      <p className="w-[183px] text-center text-body">
                        {langData?.would_you_be_interested_to_use_org_acc}
                      </p>
                      <CustomButton
                        customSize="regular"
                        customVariant="secondary"
                        onClick={() => setInterested(true)}
                      >
                        {langData?.yes_im_interested}
                      </CustomButton>
                    </div>
                  )}
                </div>

                <div
                  className={cn(
                    'absolute inset-0 hidden rounded-lg bg-black/[.64] backdrop-blur-sm',
                    interested && 'block'
                  )}
                >
                  {interested && (
                    <div className="flex h-full flex-col items-center justify-center gap-y-0.5">
                      <Smile className="text-[#257C9D]" size={32} />
                      <div className="w-[183px] text-center ">
                        <p className="text-body">
                          {langData?.thanks_for_your_interest}
                        </p>
                        <p className="text-body-sm">
                          {langData?.we_will_notify_you_when_org_acc_available}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <div
                    className={cn(
                      'absolute -right-[3.6rem] top-5 w-fit rotate-45 bg-[#EFDA1F]/[.12] px-[62px] py-2.5 text-center text-body-sm text-[#EFDA1F]',
                      (hover || interested) && 'blur-lg'
                    )}
                  >
                    {messages?.General?.coming_soon}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center gap-y-4">
              <div className="flex items-center space-x-2">
                <Checkbox id="terms" onClick={() => setShowTerms(!showTerms)} />
                <label
                  htmlFor="terms"
                  className="text-form-input-text text-white"
                >
                  {termsData?.by_clicking_continue_you_agree_to_the}{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.okahu.ai/agreements/evaluation-agreement"
                    className="underline"
                  >
                    {termsData?.terms_of_service}
                  </a>{' '}
                  {
                    termsData?.for_more_information_about_okahu_privacy_practices_see
                  }{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.okahu.ai/agreements/privacy-policy"
                    className="underline"
                  >
                    {termsData?.privacy_policy}
                  </a>
                </label>
              </div>
              {!showTerms ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button
                        disabled={!showTerms}
                        className="cursor-not-allowed rounded-sm bg-fill-button-rest px-4 py-0.5 text-btn-label text-form-input-disabled disabled:bg-fill-form-control-disabled"
                      >
                        {langData?.continue}
                      </button>
                    </TooltipTrigger>
                    <TooltipContent>
                      You need to agree to the Terms and Conditions above to
                      continue.
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <button
                  disabled={!showTerms}
                  className="rounded-sm bg-fill-button-rest px-4 py-0.5 text-btn-label disabled:bg-fill-form-control-disabled"
                  onClick={() => setShowProvisioning(true)}
                >
                  {langData?.continue}
                </button>
              )}

              <button
                onClick={handleLogout}
                className="text-body-sm text-link-active"
              >
                {messages?.General?.logout}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Provisioning />
        </>
      )}
    </>
  );
};
